//@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
//@import '~@angular/material/prebuilt-themes/pink-bluegrey.css';
//@import '~@angular/material/prebuilt-themes/purple-green.css';

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@font-face {
  font-family: Bw Modelica LTI BETA03;
  src: url("./assets/fonts/BwModelicaLTIBETA03-Bold.otf");
}

@font-face {
  font-family: Bw Modelica LTI BETA03-Light;
  src: url("./assets/fonts/BwModelicaLTIBETA03-Light.otf");
}

@font-face {
  font-family: Bw Modelica LTI BETA03-Thin;
  src: url("./assets/fonts/BwModelicaLTIBETA03-Thin.otf");
}

$primary-color: #006fba;
$secondary-color: #00afeb;
$theme-yellow: #ddae32;
$theme-blue: #2c2d8b;
$theme-greay: #d3d3d3;

// .font-bold{
//   font-family: Bw Modelica LTI BETA03;
// }

// .font-light{
//   font-family: Bw Modelica LTI BETA03-Light;
// }

// .font-thin{
//   font-family: Bw Modelica LTI BETA03-Thin;
// }

html,
body {
  height: 100%;
  overflow: hidden;
  /* Hide scrollbars */
  margin: 0;
  padding: 0;
  //font-family: Bw Modelica LTI BETA03-Light !important;
  //letter-spacing: 0.59px !important;
}

.bg {
  //background: url("/assets/img/HomePage.jpg") no-repeat center center fixed;
  background-color: #ffffff;
  background-size: cover !important;
  height: 100vh;
}

.canvas-card {
  //background: url("./assets/img/Canvas\ pattern.png") no-repeat center center fixed;
  background-size: cover;
  height: 70vh;
  padding: 1rem;
  width: 100%;
  // box-shadow: 8px 8px 12px #0000007D;
  //overflow-y: auto;
}

.sub-nav-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: #292188;
  background-size: cover;
  padding: 5px 30px;
  color: #ffffff;
  z-index: 2;
}

.float-right {
  float: right;
}

.canvas-card-white {
  padding: 1rem;
  width: 100%;
  display: block;
  background-color: #e6e4e4;
  margin-top: 4px;
}

.main-holder {
  overflow-x: hidden;
}

.back-arrow {
  color: #272283 !important;
  font-size: 30px;
  cursor: pointer;
  // margin-right: 10px;
  vertical-align: middle;
}
.project-top-img {
  border-left: 2px solid $theme-yellow;
  padding-left: 10px;
  margin-right: 10px;
  width: 60px;
}
.project-tool-name {
  color: $theme-blue;
  font-size: 18px;
  font-weight: bold;
  vertical-align: middle;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  // display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1460a3 !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
  background-color: #1460a3 !important;
}

.mat-row.hovered {
  background: rgb(225, 231, 241);
}

.mat-row.highlighted {
  // background: rgb(130, 170, 255);
  background: rgb(203, 220, 255);
}

.mat-drawer-content {
  min-height: 100vh;
}

.error-continaer {
  width: 90%;
  margin: auto;
  padding: 1px;
}

.alert-dismissable .close,
.alert-dismissible .close {
  right: 0;
  top: 0;
  font-weight: 900;
}

.table-container {
  //height: 350px;
  //overflow: auto;
  margin-right: 0%;
}

.color-primary {
  color: $primary-color !important;
}

.color-secondary {
  color: $secondary-color !important;
}

.color-theme-yellow {
  color: $theme-yellow !important;
}

.color-theme-blue {
  color: $theme-blue !important;
}

.hover {
  cursor: pointer;
}

.admin-main {
  .back-arrow {
    margin-right: 10px;
  }
  .btn-canvas-blue {
    color: #fff;
    background-color: $theme-blue;
    border-color: $theme-blue;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid;
    padding: 0.625rem 2rem;
    font-size: 1.325rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    opacity: 1;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover,
    &:visited,
    &:focus,
    &:focus-within {
      background-color: $theme-blue;
      border-color: $theme-blue;
      box-shadow: none;
      outline: none;
    }
  }
  button[disabled] {
    color: #808080;
    background-color: $theme-greay;
    border-color: $theme-greay;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover,
    &:visited,
    &:focus,
    &:focus-within {
      background-color: $theme-greay;
      border-color: $theme-greay;
      box-shadow: none;
      outline: none;
    }
  }
  .link-col {
    color: #00529c;
    cursor: pointer;
    text-decoration: underline;
  }
  .table-crud-icons {
    ::ng-deep {
      .add-text {
        &:hover,
        &:visited,
        &:focus,
        &:focus-within {
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
  .canvas-card {
    overflow-y: auto;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
/*     Global Css start  */
///////////////////////////////////////////////////////////////////////////////////////////////////////////

/*Vertical bar*/
.vertical-line {
  display: inline-block;
  border-left: 2px solid $theme-yellow;
  width: 2px;
  /*height as per requirement*/
  height: 40px;
  vertical-align: middle;
}

.vertical-line-more {
  display: inline-block;
  border-left: 2px solid $theme-yellow;
  width: 2px;
  /*height as per requirement*/
  height: 20px;
  vertical-align: middle;
}

.dropdown-menu.more-drop-down {
  margin-top: -5px;
  .dropdown-item {
    cursor: pointer;
    font-size: 14px;
  }
}

/** DataTable Global CSS**/
table.dataTable thead th,
table.dataTable thead td {
  border-bottom: 1px solid #ddd;
  background-color: #ffffff;
}
table.dataTable.no-footer {
  border-bottom: none;
}
.dataTables_wrapper .dataTables_filter input {
  border: 1px solid #ddd;
  box-shadow: none;
  padding: 6px 12px;
}
.dataTables_wrapper .dataTables_filter input:focus {
  box-shadow: none;
  border: 1px solid #ddd;
  outline: none;
}
.dataTables_wrapper select {
  border: 1px solid #ddd;
  outline: none;
}
table.dataTable tbody th,
table.dataTable tbody td {
  padding: 10px 18px;
}
table.dataTables tbody .table-icon {
  font-size: 20px;
  color: $theme-blue;
  cursor: pointer;
}

.fixed-table {
  font-family: "Roboto", sans-serif;
  color: rgba(0, 0, 0, 0.86);
  tbody {
    display: block;
    height: 300px;
    //height: calc(50vh - 200px);
    overflow: auto;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

/* Table crud Icons */
.table-crud {
  svg {
    font-size: 20px;
    margin-right: 5px;
    cursor: pointer;
  }
}

span.table-crud[disabled] {
  svg {
    color: grey;
  }
}

.table-edit-icon svg {
  color: orange;
}

.table-save-icon svg {
  color: lightgreen;
}

.table-delete-icon svg {
  color: lightcoral;
}

/**Form elements**/
/*use for textbox, dropdown and textarea*/
.canvas-textbox {
  display: block;
  width: 100%;
  height: auto;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover,
  &:visited,
  &:focus,
  &:focus-within {
    box-shadow: none;
    outline: none;
  }
}

.canvas-checkbox-label::before,
.canvas-checkbox-label::after {
  top: 1rem;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0;
}

/*Buttons*/

.btn-canvas {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  &:hover,
  &:visited,
  &:focus,
  &:focus-within {
    box-shadow: none;
    outline: none;
  }
}

.btn-canvas-blue-1 {
  background-color: $theme-blue;
  border-color: $theme-blue;
}
.btn-canvas-blue-2 {
  background-color: #006fba;
  border-color: #006fba;
}

.btn-canvas-blue-3 {
  background-color: #00ccff;
  border-color: #00ccff;
}

/**Inner page theme css***/

.inner-page-container {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;

  .inner-top-container {
    position: sticky;
    top: 0px;
    // background: url("/assets/img/HomePage.jpg") no-repeat center center fixed;
    background-color: #292188;
    background-size: cover;
    padding: 0px 35px 0px 35px;

    .step-back {
      font-size: 16px;
      cursor: pointer;
      text-decoration: none;
      color: #fff;
      .step-back-icon {
        //font-size: 18px;
        color: #ddae32;
      }
    }
    .breadcrumb {
      margin-bottom: 0px;
    }
    .breadcrumb-item.active {
      color: #ffffff;
      font-weight: 500;
      font-size: 20px;
      padding-top: 3px;
    }
    .breadcrumb-item.link-blue {
      color: #ffffff;
      cursor: pointer;
      padding-top: 5px;
    }

    .cancel-back-button {
      position: relative;
      top: 8px;
      z-index: 999;
    }
  }
  .inner-mid-container {
    background-color: #ffffff;
    padding: 10px 36px 80px 36px;
    .page-name {
      color: #111111;
    }
  }
  footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: right;
    font-size: 18px;
    padding-right: 15px;
  }
  .table-options {
    span {
      font-size: 16px;
      margin-right: 30px;
      cursor: pointer;
      color: #ffffff;
      &:last-child {
        margin-right: 0px;
      }
      svg {
        font-size: 18px;
        color: #ddae32;
      }
    }
  }
}

/** Toggle Switch Checkbox**/
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $theme-blue;
}

input:focus + .slider {
  box-shadow: 0 0 1px $theme-blue;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}

/**Custom Checkbox**/
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: $theme-blue;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/**Custom Radio Button**/
.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

.radio-container:hover input ~ .radio {
  background-color: #ccc;
}

.radio-container input:checked ~ .radio {
  background-color: $theme-blue;
}

.radio:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-container input:checked ~ .radio:after {
  display: block;
}

.radio-container .radio:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.modal {
  top: 25%;
}
.modal-backdrop.fade {
  z-index: -1;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header h3 {
  margin-bottom: 0px;
}

.form-group {
  .required {
    color: red;
  }
}
h3.popover-title,
h3.popover-header {
  margin-bottom: 10px;
}
.popover-body p {
  font-size: 14px;
}
.popover-arrow.arrow {
  display: none;
}
.limit-text {
  text-overflow: ellipsis;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
}
h1.coming-soon {
  position: absolute;
  top: 45%;
  left: 45%;
  color: #fff;
  font-size: 42px;
  font-weight: 500;
}
/**.limit-text:hover{
    text-overflow:clip;
    width:auto;
    white-space: normal;
} */

// Confirmation dialog popup
.popup-dialog {
  color: #0051a0;
  .close-icon {
    float: right;
    cursor: pointer;
    color: #0051a0;
    position: relative;
    top: -30px;
    right: -5px;
  }
  .content {
    text-align: center;
    margin: 18px 0px;
    .exl-icon {
      color: #0051a0;
      font-size: 40px;
      position: relative;
      top: 10px;
    }
    .vertical-line {
      display: inline-block;
      border-left: 2px solid #ddae32;
      width: 2px;
      height: 40px;
      vertical-align: middle;
    }
    .text {
      font-weight: 500;
      font-size: 16px;
    }
  }

  .btn-canvas-blue-2 {
    height: 35px !important;
    font-weight: 500;
    background-color: #0051a0;
  }
}
.mat-dialog-container {
  padding: 15px !important;
  overflow-x: hidden !important;
}

// Pop up style
.pop-up {
  .info-header {
    padding: 10px;
    border-bottom: 1px solid #0051a0;
  }
  .header {
    color: #0051a0;
    font-weight: 500;
    font-size: 18px;
  }

  .close-icon {
    float: right;
    cursor: pointer;
    color: #0051a0;
    position: relative;
    top: -5px;
    right: -5px;
    font-weight: 600;
  }
  .vertical-line {
    display: inline-block;
    border-left: 2px solid #ddae32;
    width: 2px;
    height: 20px;
    vertical-align: middle;
  }
  hr.margin-class {
    border-color: #0051a0;
    margin-top: 10px !important;
    margin-bottom: 0px !important;
  }
  .popup-container .field-name {
    font-weight: 500 !important;
    color: #0051a0;
  }
  .btn-canvas-blue-2 {
    height: 35px !important;
    font-weight: 500;
    color: #fff;
    background-color: #0051a0;
  }
  .vdi-button {
    height: 35px !important;
  }
  .modal-header-popup {
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #0051a0;
  }
}

.inner-top-container .field-name {
  font-weight: 500 !important;
  color: cornsilk !important;
}

.mat-toolbar-single-row {
  height: 55px !important;
  padding: 0px 10px !important;
}

.field-name {
  font-size: 16px;
  font-weight: bold;
  color: #0051a0;
}
.disabled-img {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
.field-value {
  font-size: 16px;
  color: #000;
  text-transform: capitalize;
}

.my-auto {
  padding: 13px !important;
}
.mat-vertical-stepper-header {
  padding: 18px 24px !important;
  margin: 0px;
  height: 18px !important;
}

/*Bootstrap Update 4.5.2*/
label {
  font-weight: 700;
}

.form-control-lg {
  height: calc(2.078rem + 2px);
  font-size: 0.89rem;
}
.mr-2 {
  font-size: 0.89rem;
}
.btn-lg {
  font-size: 0.89rem;
}
.p-5 {
  padding: 1.8rem !important;
}
.inner-top-container {
  position: sticky;
}
.ml-5,
.mx-5 {
  margin-left: 1.5rem !important;
}
.pt-5,
.py-5 {
  padding-top: 2.3rem !important;
}
/*End*/

.disabled-img {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.cursor {
  cursor: pointer;
}

.close_icon {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  padding-left: 5%;
  cursor: pointer;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-position: right calc(1.375em + 0.1875rem) center;
}

.word-break{
  word-break: break-word;
}
.pointer{
cursor : pointer;
}
.k-button {
  border-color: rgba(0,0,0,.08);
  color: white;
  background-color: #272283;
    background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.02));
}
.k-button:hover {
  color: white;
}
.k-button.k-button-icontext {
  border-radius: 4px;
    padding: 8px 8px;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    font-size: 14px;
    line-height: 1.4285714286;
    font-family: inherit;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    -webkit-appearance: none;
    position: relative;
}
.color-black {
  color: black;
}

.color-blue {
  color: #0056b3;
}
.userRow{
  margin-top: 2%;
  margin-bottom: 2%;
  text-align: center;
}

.breadcrumb-title{
color: white;
    padding-top: 5%;
	cursor:pointer
	}
  .title-class-header{
    font-size: 25px;
    color: #ef4f4f;
    padding-top: 3%;
    font-weight: bold;
    text-align:center;
  
  }
  